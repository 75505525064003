import DividerIcon from './DividerIconLight';
import ShowItem from './ShowItem';

const ContactSection = () => {
  return (
    <section className="page-section bg-secondary text-white" id="shows">
      <div className="container">
        <h2 className="page-section-heading text-center text-uppercase text-white mb-0">
          Shows
        </h2>

        <DividerIcon />

        <ShowItem
          dateAndTime="10/25/2024, 6:00pm-9:00pm"
          venue="The Draft, Sports Pub & Grill"
          venueLink="https://www.thedraftpleasanton.com/"
          venueLocation="4825 Hopyard Rd, Pleasanton, CA 94588"
          venueLocationLink="https://maps.app.goo.gl/u7fSULJJEjUm8nTaA"
        />
        <ShowItem
          dateAndTime="12/7/2024, 9:00pm-1:00am"
          venue="Pine Street Bar"
          venueLink="https://www.facebook.com/people/Pine-Street-Bar/100057310163993/"
          venueLocation="875 Rincon Ave, Livermore, CA 94551"
          venueLocationLink="https://maps.app.goo.gl/fudk3wWC7GSH9oZF6"
        />

        <br />

        <h3 className="fst-italic text-center text-white mb-4">
          &mdash; Previous Shows &mdash;
        </h3>

        <div class="accordion" id="previous-shows-accordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="show-accordion-heading-2024">
              <button
                class="accordion-button collapsed show-accordion-link"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                2024
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="show-accordion-heading-2024"
            >
              <div class="accordion-body">
                <ShowItem
                  dateAndTime="10/5/2024, 9:00pm-1:00am"
                  venue="Pine Street Bar"
                  venueLink="https://www.facebook.com/people/Pine-Street-Bar/100057310163993/"
                  venueLocation="875 Rincon Ave, Livermore, CA 94551"
                  venueLocationLink="https://maps.app.goo.gl/fudk3wWC7GSH9oZF6"
                />
                <ShowItem
                  dateAndTime="9/20/2024, 6:00pm-9:00pm"
                  venue="Sliver Pizzeria"
                  venueLink="https://www.sliverpizzeria.com/locations/capitol/"
                  venueLocation="3658A Capitol Ave, Fremont, CA 94538"
                  venueLocationLink="https://maps.app.goo.gl/zdWrAeUQfVQg7he29"
                />
                <ShowItem
                  dateAndTime="9/12/2024, 4:00pm-8:00pm"
                  venue="Livermore Thursday Farmers Market"
                  venueLink="https://www.livermorefarmersmarket.org/visit"
                  venueLocation="Carnegie Park, 2155 Third St, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/3LgtxEXo24eMbKwCA"
                />
                <ShowItem
                  dateAndTime="9/7/2024, 5:00pm-11:00pm"
                  venue="Irvington High School Reunion, Class of '84"
                  venueLink=""
                  venueLocation="Fremont, CA"
                  venueLocationLink=""
                />
                <ShowItem
                  dateAndTime="8/31/2024, 9:00pm-1:00am"
                  venue="Pine Street Bar"
                  venueLink="https://www.facebook.com/people/Pine-Street-Bar/100057310163993/"
                  venueLocation="875 Rincon Ave, Livermore, CA 94551"
                  venueLocationLink="https://maps.app.goo.gl/fudk3wWC7GSH9oZF6"
                />
                <ShowItem
                  dateAndTime="8/16/2024, 6:00pm-9:00pm"
                  venue="Sliver Pizzeria"
                  venueLink="https://www.sliverpizzeria.com/locations/capitol/"
                  venueLocation="3658A Capitol Ave, Fremont, CA 94538"
                  venueLocationLink="https://maps.app.goo.gl/zdWrAeUQfVQg7he29"
                />
                <ShowItem
                  dateAndTime="8/3/2024, 12:00pm-3:00pm"
                  venue="BoaVentura Winery"
                  venueLink="https://boaventuravineyard.com/"
                  venueLocation="9309 Tesla Road, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/e55FLTosBBF9Qpdg9"
                />
                <ShowItem
                  dateAndTime="7/25/2024, 4:00pm-8:00pm"
                  venue="Livermore Thursday Farmers Market"
                  venueLink="https://www.livermorefarmersmarket.org/visit"
                  venueLocation="Carnegie Park, 2155 Third St, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/3LgtxEXo24eMbKwCA"
                />
                <ShowItem
                  dateAndTime="7/6/2024, 9:00pm-1:00am"
                  venue="Pine Street Bar"
                  venueLink="https://www.facebook.com/people/Pine-Street-Bar/100057310163993/"
                  venueLocation="875 Rincon Ave, Livermore, CA 94551"
                  venueLocationLink="https://maps.app.goo.gl/fudk3wWC7GSH9oZF6"
                />
                <ShowItem
                  dateAndTime="6/22/2024, 6:00pm-9:00pm"
                  venue="Sliver Pizzeria"
                  venueLink="https://www.sliverpizzeria.com/locations/capitol/"
                  venueLocation="3658A Capitol Ave, Fremont, CA 94538"
                  venueLocationLink="https://maps.app.goo.gl/zdWrAeUQfVQg7he29"
                />
                <ShowItem
                  dateAndTime="5/25/2024, 9:00am-1:00pm"
                  venue="Niles Fremont Farmers Market"
                  venueLink="https://nilesfarmersmarket.org/visit"
                  venueLocation="Niles Town Plaza, 37482 Niles Blvd, Fremont, CA 94536"
                  venueLocationLink="https://maps.app.goo.gl/ATCDLrnRVpqi96J96"
                />
                <ShowItem
                  dateAndTime="5/4/2024, 7:30pm-9:30pm"
                  venue="LHS Class Reunion '82 & '83"
                  venueLink=""
                  venueLocation="Livermore, CA"
                  venueLocationLink=""
                />
                <ShowItem
                  dateAndTime="4/27/2024, 9:00am-1:00pm"
                  venue="Niles Fremont Farmers Market"
                  venueLink="https://nilesfarmersmarket.org/visit"
                  venueLocation="Niles Town Plaza, 37482 Niles Blvd, Fremont, CA 94536"
                  venueLocationLink="https://maps.app.goo.gl/ATCDLrnRVpqi96J96"
                />
                <ShowItem
                  dateAndTime="4/11/2024, 4:00pm-8:00pm"
                  venue="Livermore Thursday Farmers Market"
                  venueLink="https://www.livermorefarmersmarket.org/visit"
                  venueLocation="Carnegie Park, 2155 Third St, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/3LgtxEXo24eMbKwCA"
                />
                <ShowItem
                  dateAndTime="3/17/2024, 6:00pm-8:00pm"
                  venue="BoaVentura Winery (Private Event)"
                  venueLink="https://boaventuravineyard.com/"
                  venueLocation="9309 Tesla Road, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/e55FLTosBBF9Qpdg9"
                />
                <ShowItem
                  dateAndTime="2/3/2024, 9:00pm-1:00am"
                  venue="Pine Street Bar"
                  venueLink="https://www.facebook.com/people/Pine-Street-Bar/100057310163993/"
                  venueLocation="875 Rincon Ave, Livermore, CA 94551"
                  venueLocationLink="https://maps.app.goo.gl/fudk3wWC7GSH9oZF6"
                />
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="show-accordion-heading-2023">
              <button
                class="accordion-button collapsed show-accordion-link"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                2023
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="show-accordion-heading-2023"
            >
              <div class="accordion-body">
                <ShowItem
                  dateAndTime="11/4/2023, 12:00pm-4:00pm"
                  venue="BoaVentura Winery"
                  venueLink="https://boaventuravineyard.com/"
                  venueLocation="9309 Tesla Road, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/e55FLTosBBF9Qpdg9"
                />
                <ShowItem
                  dateAndTime="10/28/2023, 3:00pm-6:00pm"
                  venue="Mia Nova Vineyards (Private Event)"
                  venueLink=""
                  venueLocation="Boulder Creek, CA"
                  venueLocationLink=""
                />
                <ShowItem
                  dateAndTime="10/12/2023, 4:00pm-8:00pm"
                  venue="Livermore Thursday Farmers Market"
                  venueLink="https://www.livermorefarmersmarket.org/visit"
                  venueLocation="Carnegie Park, 2155 Third St, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/3LgtxEXo24eMbKwCA"
                />
                <ShowItem
                  dateAndTime="9/28/2023, 4:00pm-8:00pm"
                  venue="Livermore Thursday Farmers Market"
                  venueLink="https://www.livermorefarmersmarket.org/visit"
                  venueLocation="Carnegie Park, 2155 Third St, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/3LgtxEXo24eMbKwCA"
                />
                <ShowItem
                  dateAndTime="8/19/2023 12:00pm-4:00pm"
                  venue="BoaVentura Winery"
                  venueLink="https://boaventuravineyard.com/"
                  venueLocation="9309 Tesla Road, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/e55FLTosBBF9Qpdg9"
                />
                <ShowItem
                  dateAndTime="7/13/2023, 4:00pm-8:00pm"
                  venue="Livermore Thursday Farmers Market"
                  venueLink="https://www.livermorefarmersmarket.org/visit"
                  venueLocation="Carnegie Park, 2155 Third St, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/3LgtxEXo24eMbKwCA"
                />
                <ShowItem
                  dateAndTime="7/2/2023, 9:00am-1:00pm"
                  venue="Livermore Sunday Farmers Market"
                  venueLink="https://www.livermorefarmersmarket.org/visit"
                  venueLocation="Second St. (between J & L), Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/FB2hazsSELLd8mbz5"
                />
                <ShowItem
                  dateAndTime="6/11/2023, 5:00pm-9:00pm"
                  venue="Wine Club Release Party (Private Event)"
                  venueLink=""
                  venueLocation="Livermore, CA"
                  venueLocationLink=""
                />
                <ShowItem
                  dateAndTime="5/24/2023, 7:00pm-10:00pm"
                  venue="Antlers Tavern"
                  venueLink="https://antlers-tavern.business.site/"
                  venueLocation="2284 San Pablo Ave, Pinole, CA 94564"
                  venueLocationLink="https://goo.gl/maps/updjn2ocs9QhbA5u6"
                />
                <ShowItem
                  dateAndTime="4/13/2023, 4:00pm-8:00pm"
                  venue="Livermore Thursday Farmers Market"
                  venueLink="https://www.livermorefarmersmarket.org/visit"
                  venueLocation="Carnegie Park, 2155 Third St, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/3LgtxEXo24eMbKwCA"
                />
                <ShowItem
                  dateAndTime="4/12/2023, 7:00pm-10:00pm"
                  venue="Antlers Tavern"
                  venueLink="https://antlers-tavern.business.site/"
                  venueLocation="2284 San Pablo Ave, Pinole, CA 94564"
                  venueLocationLink="https://goo.gl/maps/updjn2ocs9QhbA5u6"
                />
                <ShowItem
                  dateAndTime="3/15/2023, 7pm-10pm"
                  venue="Antlers Tavern"
                  venueLink="https://antlers-tavern.business.site/"
                  venueLocation="2284 San Pablo Ave, Pinole, CA 94564"
                  venueLocationLink="https://goo.gl/maps/updjn2ocs9QhbA5u6"
                />
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="show-accordion-heading-2022">
              <button
                class="accordion-button collapsed show-accordion-link"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                2022
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="show-accordion-heading-2022"
            >
              <div class="accordion-body">
                <ShowItem
                  dateAndTime="9/11/2022, 5:00pm-8:00pm"
                  venue="Wine Club Private Event"
                  venueLink=""
                  venueLocation="Livermore, CA"
                  venueLocationLink=""
                />
                <ShowItem
                  dateAndTime="7/9/2022, 1:00pm-5:00pm"
                  venue="BoaVentura Winery"
                  venueLink="https://boaventuravineyard.com/"
                  venueLocation="9309 Tesla Road, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/e55FLTosBBF9Qpdg9"
                />
                <ShowItem
                  dateAndTime="5/21/2022 2:00pm-5:00pm"
                  venue="BoaVentura Winery"
                  venueLink="https://boaventuravineyard.com/"
                  venueLocation="9309 Tesla Road, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/e55FLTosBBF9Qpdg9"
                />
                <ShowItem
                  dateAndTime="5/7/2022, 2:00pm-5:00pm"
                  venue="BoaVentura Winery"
                  venueLink="https://boaventuravineyard.com/"
                  venueLocation="9309 Tesla Road, Livermore, CA 94550"
                  venueLocationLink="https://goo.gl/maps/e55FLTosBBF9Qpdg9"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
